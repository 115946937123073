<template>
    <v-container fluid>
        <div>
            <h2>Our Partners </h2>
            <div class="flag_images text-center" v-show="!show_region">
                <img src="/country-data/world.png" style="max-width:400px;" alt="">
            </div>

            <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                    <div class="flag_images text-center mb-12"

                         v-show="show_region && !show_country">
                        <img style="max-width:400px;" :src="'/country-data/'+region.link+'.png'" alt="">
                    </div>

                    <div class="text-center mb-12">
                        <v-btn
                                v-show="show_region && !show_country"
                                color="blue-grey darken-4"
                                outlined
                                @click="cancel_region()"
                        >
                            Go Back
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="6"
                       v-if="!show_region"
                       v-bind:class="{'text-right': !is_odd_number(index)}"
                       v-for="(r, index) in regions">
                    <span @click="load_countries(r)">
                        {{ r.name }}
                    </span>
                </v-col>

                <v-col cols="12" sm="6" md="6"
                       v-show="show_region && !show_country"
                       v-if="country.supermarkets.length ||
                       country.departments.length ||
                       country.specialized.length"
                       v-bind:class="{'text-right': !is_odd_number(index)}"
                       v-for="(country, index) in region.countries">
                    <span @click="load_country_detail(country)">
                        {{ country.name }}
                    </span>
                </v-col>



                <v-col cols="12" sm="12" md="12" lg="12" v-show="show_country">
                    <div class="map-location text-center">
                        <img :src="country.flag_image"  style="max-width:200px; height:auto;" alt="">
                    </div>
                    <div class="text-center mb-12">
                        <v-btn
                              color="blue-grey darken-4"
                              outlined
                              @click="cancel_country()"
                            >
                              Go Back
                            </v-btn>
                    </div>
                    <v-card color="basil">
                        <v-card-title class="text-center justify-center py-6">
                            <h1 class="font-weight-bold display-3 basil--text">
                                {{ country.name }}
                            </h1>
                        </v-card-title>

                        <v-tabs
                                v-model="tab"
                                background-color="transparent"
                                color="basil"
                                grow
                        >
                            <v-tab
                                    v-for="(item, index) in items"
                                    :key="index"
                            >
                                {{ item.name }}
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tabs-items v-model="tab">
                                <v-tab-item
                                        v-for="(item, index) in items"
                                        :key="index"
                                >
                                    <v-card flat color="basil">
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12"
                                                       sm="6"
                                                       md="4"
                                                       v-for="i in item.items"
                                                       class="text-center"
                                                >
                                                    <img :src="i.logo" style="max-width:150px; height:auto;" alt="">
                                                    <br>
                                                    {{ i.description }}
                                                    <sup>
                                                        {{ i.count }}
                                                    </sup>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "OurPartners",
        data: () => ({
            title: "Our Partners",
            regions: [
                {
                    name: 'USA and Canada',
                    link: 'usa-and-canada',
                },
                {
                    name: 'Africa',
                    link: 'africa'
                },
                {
                    name: 'Latin America',
                    link: 'latin-america'
                },
                {
                    name: 'Oceania',
                    link: 'oceania'
                },
                {
                    name: 'Europe',
                    link: 'europe'
                },
                {
                    name: 'Asia',
                    link: 'asia'
                },
            ],
            region: {},
            country: {},
            show_region: false,
            show_country: false,
            tab:false,
            items: [
                {
                    id:1,
                    name: 'Supermarkets'
                },
                {
                    id:2,
                    name: 'Departments'
                },
                {
                    id:3,
                    name: 'Specialized Stores'
                }
            ]
        }),
        created() {
            console.log("TEST Partners");
        },
        methods: {
            is_odd_number(n)
            {
                return n % 2;
            },
            load_countries(region)
            {
                this.$hget('/store/countries/?region='+region.link).then((response) => {
                        if(typeof response.data !== 'undefined')
                        {
                            region.countries = [];
                            response.data.forEach(function(d){
                                if(d.supermarkets.length || d.departments.length || d.specialized.length)
                                {
                                    region.countries.push(d);
                                }
                            });
                            this.region = region;
                            this.show_region = true;
                        }
                    });
            },
            cancel_country()
            {
                this.show_country = false;

            },
            cancel_region()
            {
                this.show_region = false;

            },
            load_country_detail(country)
            {
                this.show_region = true;
                this.show_country = true;
                this.items[0].items = country.supermarkets;
                this.items[1].items = country.departments;
                this.items[2].items = country.specialized;
                this.country = country;
            }
        }
    }
</script>
<style lang="scss">
    .country-list {
        padding: 0;
        margin: 0;
        li {
            display: block;
        }
    }
</style>
<style scoped>

</style>
